div.horizontal_scrollmenu {
  background-color: #333;
  overflow: auto;
  white-space: nowrap;
  border-radius: 10px;
}

div.horizontal_scrollmenu .element {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

div.horizontal_scrollmenu .element.active,
div.horizontal_scrollmenu .element:hover {
  background-color: #777;
}

