
#media_wrap {
    display: flex;
    flex-wrap: wrap; // Allow items to wrap to the next line
    gap: 10px; // Adjust the gap between items as needed
    align-items: flex-start;
    justify-content: flex-start; // Align items to the start of the container
    margin: 15px auto;

    .media_library_element {
        width: calc(16.66% - 10px); // 16.66% for 6 items per row, minus gap
        height: 200px;
        position: relative;
        box-sizing: border-box; // Ensure padding and border are included in width

        img {
            max-width: 100%; // Ensure images are responsive
            width: auto;
            height: auto;
        }

        label.Polaris-Choice {
            position: absolute;
            right: 5px;
            top: 5px;
        }
    }
}
