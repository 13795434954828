div.image-previews {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  height: 120px;
  gap: 15px;
  width: max-content;
  overflow-y: hidden;
  align-content: space-around;
  -webkit-overflow-scrolling: 'touch';
  scrollbar-width: 'thin';
  padding: 10px 0px;
  overflow-x: auto;
  flex-wrap: nowrap;
}

.image-preview-outerwrap {
  overflow-x: auto;
  overflow-y: hidden;
  height: 120px;
  width: 100%;
}

.post {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 20px;
  background-color: white;

  .post-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
    }

    .post-user-info {
      h4 {
        margin: 0;
        font-size: 16px;
      }

      span {
        color: gray;
        font-size: 12px;
      }
    }
  }

  .post-content {
    margin-bottom: 10px;

    p {
      font-size: 14px;
      margin: 0 0 10px;
    }

    .main-image-container {
      margin-bottom: 10px;

      .main-post-image {
        width: 100%;
        max-height: 400px;
        object-fit: cover;
        border-radius: 8px;
      }
    }

    .slide-images-container {
      display: flex;
      overflow-x: auto;
      gap: 10px;

      .slide-post-image {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 8px;
      }
    }
  }

  .post-actions {
    display: flex;
    justify-content: space-around;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding: 15px 0;
  }

  /* Style cho comments */
  .post-comments {
    margin-top: 15px;

    .comment {
      display: flex;
      margin-bottom: 10px;
      align-items: flex-start;

      .comment-avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 10px;
      }

      .comment-body {
        background-color: #f0f2f5;
        padding: 10px;
        border-radius: 16px;
        max-width: 80%;

        h5 {
          margin: 0;
          font-size: 14px;
          font-weight: bold;
        }

        p {
          margin: 5px 0 0;
          font-size: 13px;
        }
      }
    }
  }

  /* Form thêm comment */
  .comment-form {
    display: flex;
    margin-top: 10px;

    input {
      flex: 1;
      padding: 10px;
      border-radius: 20px;
      border: 1px solid #ddd;
      margin-right: 10px;
      font-size: 14px;
    }

    button {
      padding: 5px 15px;
      border: none;
      border-radius: 20px;
      background-color: #4267b2;
      color: white;
      font-size: 14px;
      cursor: pointer;
    }
  }
}

/**** NEW POST ******************/

.new-post {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 20px;
  background-color: white;

  .new-post-header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;

    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
    }

    textarea {
      flex: 1;
      border: 1px solid #ddd;
      border-radius: 8px;
      padding: 10px;
      font-size: 14px;
      resize: none;
      min-height: 60px;
    }
  }

  .image-previews {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;

    .image-preview-container {
      position: relative;
      margin-right: 10px;

      .image-preview {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 8px;
      }

      button {
        position: absolute;
        top: 5px;
        right: 5px;
        background: rgba(0, 0, 0, 0.6);
        color: white;
        border: none;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
  }

  .new-post-actions {
    display: flex;
    justify-content: space-between;

    .action-btn {
      background: none;
      border: 1px solid #ddd;
      padding: 5px 10px;
      border-radius: 8px;
      cursor: pointer;

      &:hover {
        background-color: #f0f2f5;
      }
    }

    input[type='file'] {
      display: none;
    }
  }
}

.image-previews {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;

  .image-preview-container {
    position: relative;
    margin-right: 10px;

    .image-preview {
      width: 100px;
      height: 100px;
      object-fit: cover;
      border-radius: 8px;
    }

    button {
      position: absolute;
      top: 5px;
      right: 5px;
      background: rgba(0, 0, 0, 0.6);
      color: white;
      border: none;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}
