.voucher-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.voucher {
  background-color: white;
  border-radius: 20px;
  padding: 15px;
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.voucher_deactive {
  background-color: #e3e0e0;
}

.voucher:hover {
  background-color: rgb(234, 252, 233);
  transition: all ease-in-out 0.3s;
}

.voucher-date {
  font-size: 14px;
  color: #ff647f;
  font-weight: bold;
  margin-bottom: 10px;
}

.voucher-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.voucher-value {
  font-size: 24px;
  font-weight: bold;
  color: #00b8e6;
  width: 300px;
  line-height: 1.4;
  white-space: normal;
  margin-bottom: 2px;
}

.voucher-icon img {
  width: 50px;
  height: 50px;
}

.voucher-terms {
  font-size: 12px;
  color: #999;
  margin-top: 10px;
}

