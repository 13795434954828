
.jamviet_mention_form--singleLine {

    .jamviet_mention_form__highlighter--singleLine {
      background-color: #FFF;
    }
    
  
    .jamviet_mention_form__input,
    .jamviet_mention_form__highlighter {
        // font-size: var(--p-font-size-200);
        font-weight: var(--p-font-weight-regular);
        // line-height: var(--p-font-line-height-2);
        // letter-spacing: initial;
        position: relative;
        display: block;
        flex: 1 1;
        width: 100%;
        min-width: 0;
        min-height: 2.25rem;
        margin: 0;
        padding: calc((2.25rem - var(--p-font-line-height-3) - var(--p-space-05))/2) var(--p-space-3);
        // background: #FFF !important;
        border: var(--p-border-width-1) solid transparent;
        // font-family: var(--p-font-family-sans);
        -webkit-appearance: none;
        appearance: none;
        caret-color: var(--p-color-text);
        color: var(--p-color-text);
    }
  
    .jamviet_mention_form_mention {
      background-color: #d5d5d5;
    }
  
    .jamviet_mention_form__suggestions__list {
        border: 1px solid #ccc;
        min-width: 150px;
      .jamviet_mention_form__suggestions__item  {
        padding: 5px;
      }
      .jamviet_mention_form__suggestions__item--focused  {
        background-color: #00796B;
        color: #fff
      }  
    }
  }