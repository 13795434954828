.date-input {
  display: flex;
  align-items: center;
  margin: 5px -5px;
  padding: 5px;
  background-color: #eee;

  &.has_error {
    background-color: #ffe6e6;
  }
  &.has_error input {
    border-bottom-color: red !important;
  }

  input {
    color: #333;
    width: 60px;
    padding: 5px;
    margin: 0 5px;
    text-align: center;
    font-size: 14px;
    border: none;
    border-bottom: 1px solid #333;
    background: transparent;
  }

  input.input_has_error {
    border-bottom: 1px solid red;
    color: red;
  }

  span {
    font-size: 16px;
  }
}

