#login_register_outer_wrap {
  min-height: 100vh;
  min-width: 100vw;
  background: url('../images/login_background.jpg') no-repeat center center #05284e;
  background-size: cover;

  #login_page {
    position: relative;
    margin-top: 15px;
    width: 400px;
  }
}

