


#product_gallery {
    overflow-x: scroll;
    overflow-y: hidden;
    margin: 15px 0;

    .product_gallery_wrap {
        display: inline-flex;
        gap: 10px;    
    }

    .product_gallery_element {
        position: relative;
            width: 100px;
            height: 100px;
            text-align: center;
            display: flex;
            align-content: center;
            align-items: center;
            background-color: antiquewhite;
    }
    img.product_gallery_thumbnail {
        max-width: 100px;
        height: auto;
    }
    
    button.Polaris-Button {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 9;
        background-color: #00000070;
    }
    button.Polaris-Button svg {
        fill: #fff
    }
}
