html {
  scroll-behavior: smooth;
}

img {
  max-width: 100%;
}

/* Input and textarea styles */
input, textarea, p, span, h1, h2, h3, h4, h5, h6 , a, div{
  font-family: sans-serif, "Segoe UI Emoji", "Apple Color Emoji";
}
/** Cái gì click mà không phải thẻ A thì phải CLICKABLE */
.clickable {
  cursor: pointer;
}

.hidden {
  display: none;
}

code {
  color: var(--p-color-text-critical);
}

pre {
  white-space: pre-wrap;
  border: 1px solid #ccc;
  padding: 5px;
}

button.link_format {
  align-items: normal;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgb(0, 0, 238);
  border-style: none;
  box-sizing: content-box;
  color: rgb(0, 0, 238);
  cursor: pointer;
  display: inline;
  font: inherit;
  height: auto;
  padding: 0;
  perspective-origin: 0 0;
  text-align: start;
  text-decoration: underline;
  transform-origin: 0 0;
  width: auto;
  -moz-appearance: none;
  -webkit-logical-height: 1em; /* Chrome ignores auto, so we have to use this hack to set the correct height  */
  -webkit-logical-width: auto; /* Chrome ignores auto, but here for completeness */
}

/* Mozilla uses a pseudo-element to show focus on buttons, */
/* but anchors are highlighted via the focus pseudo-class. */

@supports (-moz-appearance: none) {
  /* Mozilla-only */
  button.link_format::-moz-focus-inner {
    /* reset any predefined properties */
    border: none;
    padding: 0;
  }
  button.link_format:focus {
    /* add outline to focus pseudo-class */
    outline-style: dotted;
    outline-width: 1px;
  }
}

/* Responsive layout - hide layout in mobile */
@media (max-width: 800px) {
  .hide_mobile {
    display: none;
  }
}

.service_thumbnail_inner {
  min-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.service_thumbnail-avatar {
  width: 115px;
  height: 115px;
  cursor: pointer;
  position: relative;
}

.service_thumbnail-avatar img {
  min-width: 100%;
  height: 115px;
  width: 100%;
  border: 1px solid dashed;
}

.change-token {
  display: inline-flex;
  margin-top: 24px;
}

.news_thumbnail_inner {
  max-width: 935px;
  margin: auto;
  /* padding: 15px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.news_thumbnail-avatar {
  width: 100%;
  height: 115px;
  cursor: pointer;
  position: relative;
}

.news_thumbnail-avatar img {
  min-width: 100%;
  height: 115px;
  width: 100%;
  border: 1px solid dashed;
}

.profile-description {
  font-size: 12px;
  line-height: 16px;
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  .profile-description {
    text-align: center;
  }

  .profile_inner {
    flex-direction: column;
  }

  .Polaris-Page {
    padding: 10px;
  }
}

.paginationtable_wrap {
  padding: 10px;
  margin-bottom: 15px;

  .paginationtable_item {
    cursor: pointer;
    padding: 0 10px;
  }

  .paginationtable_item.disabled {
    cursor: default;
  }

  .paginationtable_item.active {
    cursor: default;
    border-radius: 6px;
    border: 1px solid #ccc;
    padding: 0 10px;
  }
}

.small-icon {
  font-size: 14px;
  padding: 0;
  height: auto;
  display: inline-block;
  vertical-align: middle;
}

.small-icon svg {
  vertical-align: middle;
  width: 17px;
  fill: #646464;
}

.small-icon span {
  display: inline-block;
  vertical-align: middle;
  color: #646464;
}

.big-icon {
  font-size: 14px;
  padding: 0;
  height: 100%;
  display: inline-flex;
  width: 3rem;
  margin-right: 20px;

  svg {
    vertical-align: middle;
    width: unset;
    fill: #646464;
  }

  span {
    display: inline-flex;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    color: #646464;
  }
}

.post_title {
  font-weight: bold;
  color: rgb(6, 136, 49);
}
.post_excerpt {
  color: #999;
}

/**!SECTION
* Jamviet.com fixed topbar menu
*/

.Polaris-TopBar__SecondaryMenu {
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.Polaris-Filters__Container {
  border: none;
}

/* Timeline Container */
.timeline {
  background: var(--primary-color);
  margin: 20px auto;
  position: relative;

  /* Outer Layer with the timeline border */
  .outer {
    border-left: 3px dotted #ccc;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .card {
    position: relative;
    margin: 0 0 25px 20px;
    width: 100%;
  }

  .timeline_handler {
    position: absolute;
    width: 25px;
    height: 25px;
    left: -34px;
    z-index: 98;
    background-color: #d5d5d5;
    border-radius: 50%;
    top: -2px;
  }

  .timeline_handler::after {
    content: '';
    position: absolute;
    background-color: rgb(62, 66, 66);
    width: 5px;
    height: 5px;
    border-radius: 999px;
    left: 9px;
    border: 3px solid rgb(40, 42, 43);
    top: 9px;
  }

  .title {
    color: orangered;
    position: relative;
  }
  .card .time .author {
    font-size: 12px;
    font-weight: bold;
    color: var(--p-color-text-interactive);
    margin: 5px 0;
  }
  .card .time {
    font-size: 12px;
    color: #959595;
  }
  .card .time .hour {
    font-size: 16px;
    color: #000;
    margin-right: 10px;
  }

  .step-item {
    margin: 2.5rem 0;
    font-size: 17px;
    left: -20px;
    position: relative;
    padding: 5px 0;
    background-color: #f2f2f2;
    letter-spacing: -0.9px;
    color: var(--p-color-text-subdued);
  }
}

.timeline-wraper {
  position: relative;
  padding: 45px 0;

  .timeline-label {
    width: 50px;
    flex-shrink: 0;
    position: relative;
    color: #333333;
  }

  .timeline-item {
    display: flex;
    align-items: flex-start;
    position: relative;
    margin-bottom: 1.7rem;
  }

  .timeline-badge {
    flex-shrink: 0;
    background-color: var(--p-color-bg-app);
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: relative;
    margin-top: 3px;
    margin-left: -0.4rem;
    padding: 3px !important;
    border: 3px solid var(--p-color-bg-interactive) !important;
  }

  .badge_0 {
    border-color: var(--p-color-icon-info) !important;
  }
  .badge_4 {
    border-color: var(--p-color-bg-critical-strong) !important;
  }
  .badge_2 {
    border-color: var(--p-color-bg-info-strong) !important;
  }
  .badge_3 {
    border-color: var(--p-color-bg-magic-strong) !important;
  }
  .badge_1 {
    border-color: var(--p-color-icon-primary) !important;
  }
  .badge_5 {
    border-color: var(--p-color-text-magic) !important;
  }

  .timeline-content {
    margin-left: 0.5rem;
    flex-grow: 1;
  }
}

.timeline-wraper::before {
  content: '';
  position: absolute;
  left: 51px;
  width: 3px;
  top: 0;
  bottom: 0;
  background-color: var(--p-border-neutral-subdued);
}

.home_analytics {
  padding: 15px;
  background: url('../images/ecommerce-bg.png') no-repeat center right #fff;
  background-position: right bottom;
  background-size: 230px;
  border-radius: 8px;

  .home_analytics_body {
    display: flex;
    padding: 1.2rem 0;
    align-content: center;
    justify-content: flex-start;
    align-items: center;

    .home_analytics_badget {
      border: 2px dashed #ccc;
      border-radius: 6px;
      padding: 5px 10px;
      margin-right: 15px;
      min-width: 111px;

      .num {
        font-size: 20px;
        font-weight: bold;
        color: var(--p-color-bg-critical-strong-active);
      }
      .des {
        font-size: 12px;
        color: #404040;
      }
    }
  }
}

.has_vip {
  position: relative;
}

.has_vip::after {
  content: '';
  position: absolute;
  background: url('../images/StarFilledMinor.svg') no-repeat left center transparent;
  background-size: 8px 8px;
  width: 8px;
  height: 8px;
  top: 0;
  left: -13px;
}

.filterWrapper {
  padding-left: 10px;

  .FilterPill {
    padding: 2px 12px;
    border: 1px solid #ccc;
    border-radius: 20px;
    font-size: 12px;
    cursor: pointer;
    margin-right: 5px;
  }
}

.fullScreenPage {
  position: absolute;
  z-index: 398;
  left: 0;
  top: 0;
  width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  background-color: #fff;

  .fullScreenHead {
    position: fixed;
    z-index: 399;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);

    .Polaris-FullscreenBar {
      background-color: unset !important;
    }
  }
  .fullscreenBody {
    padding: 0;
    margin-bottom: 4rem;
    margin-top: 58px;
    height: 100%;
    overflow-x: hidden;

    .Polaris-Box {
      border: none !important;
    }
  }

  .sale_order_note > div {
    width: 300px;
    white-space: normal;
  }
}

.table_app_cellMinWidth {
  min-width: 150px;
}

#customer_detail {
  padding: var(--p-space-4);
}

.BorderGrid-row {
  display: block;
  width: 100%;

  .BorderGrid-cell {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .Progress {
    display: flex;
    height: 8px;
    background-color: var(--p-color-bg-app-selected);
    border-radius: 6px;
    outline: 1px solid transparent;
  }
  .progress_size_normal {
  }
  .progress_size_medium {
    height: 16px;
  }
  .progress_size_big {
    height: 32px;
  }

  .Progress-item + .Progress-item {
    margin-left: 2px;
  }
  .Progress-item {
    outline: 2px solid transparent;
    height: 100%;
    position: relative;
  }
  .Progress-item .m {
    display: none;
    position: absolute;
  }
  .Progress-item .m::after {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #ccc;
    content: '';
    position: absolute;
    bottom: -8px;
    left: 14px;
    z-index: 100;
  }
  .Progress-item.z > .m {
    display: block;
    position: absolute;
    z-index: 99;
    top: -32px;
    border-bottom: 1px solid #ccc;
    background-color: #fff;
    box-shadow: 0px 0px 3px 2px #ccc;
    padding: 3px 5px;
    min-width: 160px;
  }
  .list-style-none {
    list-style: none !important;
    padding: 0;
  }
  .d-inline {
    display: inline !important;
    margin-right: 5px;
  }
  .octicon {
    display: inline-block;
    overflow: visible !important;
    vertical-align: text-bottom;
    fill: currentColor;
  }
}

.list_user_loosing_space {
  display: flex;
  gap: 0;
  span.Polaris-Avatar {
    margin-right: -6px;
    box-shadow: 1px 1px 3px 1px #ccc;
  }
}

.Polaris_table_tr_light_text {
  color: #fff;
  .Polaris-Button__Content {
    color: #fff;
  }
  .Polaris-Button--plain:hover svg,
  svg,
  svg:hover {
    color: #fff !important;
    fill: currentColor;
  }
  button {
    color: #fff;
  }
  button:hover {
    color: #fff;
  }
}

.Polaris-IndexTable--statusSubdued {
  background-color: var(--p-color-avatar-style-four-background-experimental) !important;
  .Polaris-IndexTable__TableCell:first-child,
  .Polaris-IndexTable__TableCell:last-child {
    background-color: var(--p-color-avatar-style-four-background-experimental) !important;
  }
}

.Polaris-IndexTable__Table {
  .draggable-table__drag {
    font-weight: lighter;
    text-transform: capitalize;
    position: absolute;
    width: 100%;
    border: 1px solid #f1f1f1;
    z-index: 50;
    cursor: grabbing;
    opacity: 0.9;
  }

  tbody {
    tr.is-dragging {
      cursor: move;
    }

    td:visited,
    td:active,
    td:target,
    td:focus-visible,
    td:focus-within,
    td:focus {
      border: none !important;
      outline: 1px solid rgba(255, 255, 255, 0);
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
    }
    td::-moz-focus-inner {
      border: 0;
    }
  }
}

#context_menu {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  background: #fff;
  color: #555;
  font-family: sans-serif;
  font-size: 11px;
  -webkit-transition: opacity 0.1s ease-in-out;
  -moz-transition: opacity 0.1s ease-in-out;
  -ms-transition: opacity 0.1s ease-in-out;
  -o-transition: opacity 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out;
  -webkit-box-shadow: 2px 2px 2px 0px rgba(143, 144, 145, 1);
  -moz-box-shadow: 2px 2px 2px 0px rgba(143, 144, 145, 1);
  box-shadow: 2px 2px 2px 0px rgba(143, 144, 145, 1);
  padding: 0px;
  border: 1px solid #c6c6c6;
  z-index: 9999;
}

#context_menu a {
  display: block;
  color: #555;
  text-decoration: none;
  padding: 6px;
  width: 100%;
  position: relative;
}

#context_menu a span {
  color: #bcb1b3;
  float: right;
}

#context_menu .tool a:hover {
  color: #fff;
  background-color: #4b4b4b;
}

#context_menu hr {
  border: 1px solid #ebebeb;
  border-bottom: 0;
}

#context_menu .color_bookmark {
  display: flex;
  padding: 5px;
  gap: 5px;
  justify-content: flex-start;
  align-content: center;
}

#context_menu .color_bookmark a {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid transparent;
  position: relative;
}
#context_menu .color_bookmark a:hover {
  border: 1px solid black;
}
#context_menu .color_bookmark a.black {
  background-color: black;
}
#context_menu .color_bookmark a.red {
  background-color: red;
}
#context_menu .color_bookmark a.blue {
  background-color: blue;
}
#context_menu .color_bookmark a.green {
  background-color: green;
}
#context_menu .color_bookmark a.yellow {
  background-color: yellow;
}
#context_menu .color_bookmark a.aqua {
  background-color: aqua;
}
#context_menu .color_bookmark a.none {
  background: url('../images/none_banned.png') no-repeat center center #fff;
  background-size: contain;
}

/**!SECTION
* Task list
*/

.task_list_wrap {
  height: auto;

  .task_list_template {
    display: flex;
    align-content: center;
    justify-content: flex-start;
    gap: 2px;
    margin-bottom: 10px;

    .task_mark {
      // background: url('./dot.svg') no-repeat center center transparent;
      // background-size: 10px 10px;
      width: 15px;
      height: 15px;
      position: relative;
      margin-right: 15px;
    }

    span::after {
      content: ' ';
      position: relative;
      width: 15px;
      height: 15px;
      display: block;
      background-color: rgb(133, 133, 133);
      border-radius: 4px;
      top: 3px;
    }
    span.processing::after {
      background-color: red;
    }
    span.cancel::after {
      background-color: black;
    }
    span.done::after {
      background-color: green;
    }

    .task_content {
      .task_title {
        font-weight: bold;
        margin-bottom: 5px;
      }
      .task_assign {
        color: #757575;
        font-size: 12px;
      }
    }
  }
}

/** END TASK LIST TEMPLATE */

