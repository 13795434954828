#user_wallet_card_profile {
    
    a {
        text-decoration: none;
        color: unset;
        cursor: pointer;
        &:hover {
            text-decoration: none;
        }
    }
}