/*
  Vote star for customer ...
*/

.rate_wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0;
  font-size: 12px;
}

.rate {
  padding: 0 10px;
  position: relative;
  transition: color 0.2s ease;
}
.rate:not(:checked) > input {
  position: absolute;
  top: -1px;
  visibility: hidden;
  transition: color 0.2s ease;
}
.rate:not(:checked) > label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 22px;
  color: #ccc;
  transition: color 0.2s ease;
}
.rate:not(:checked) > label:before {
  content: '★ ';
}
.rate > input:checked ~ label {
  color: #ffd746;
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
  color: #deb217;
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
  color: #c59b08;
}

.star_wrap {
  display: inline-block;
  width: 75px;
  position: relative;
  height: 20px;
  margin-right: 10px;
  vertical-align: middle;
}

.star_wrap.small .star {
  font-size: smaller;
  width: 12px;
  height: 12px;
}

.star {
  display: inline-block;
  width: 15px;
  height: 15px;
  background: url('../images/StarOutlineMinor.svg') no-repeat center center transparent;
}
.star.checked {
  background: url('../images/StarFilledMinor.svg') no-repeat center center transparent;
}

