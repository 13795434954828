#order_transport_history_wrap {
  border-left: 2px solid #ccc;

  .order_transport_history {
    position: relative;
    z-index: 9;
    margin-bottom: 15px;
    margin-left: 15px;

    &.first_element .order_transport_history_item_dot {
      background-color: #26aa99;
    }
    &.first_element * {
      color: #26aa99;
    }

    .order_transport_history_item_dot {
      position: absolute;
      background-color: #999;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      left: -22px;
      top: 12px;
    }

    .order_transport_history_item_status_created {
      background: url('../images/order_placed_3x.png') no-repeat center center transparent;
      background-size: contain;
      width: 25px;
      height: 25px;
      left: -28px;
      top: 5px;
    }

    .order_transport_history_item_status_preparing {
      background: url('../images/preparing_to_ship_3x.png') no-repeat center center transparent;
      background-size: contain;
      width: 25px;
      height: 25px;
      left: -28px;
      top: 5px;
    }
    .order_transport_history_item_status_flying {
      background: url('../images/transit_oversea_3x.png') no-repeat center center transparent;
      background-size: contain;
      width: 25px;
      height: 25px;
      left: -28px;
      top: 5px;
    }
    .order_transport_history_item_status_packing {
      background: url('../images/processing_oversea_shipment_3x.png') no-repeat center center transparent;
      background-size: contain;
      width: 25px;
      height: 25px;
      left: -28px;
      top: 5px;
    }
    .order_transport_history_item_status_transporting,
    .order_transport_history_item_status_moving {
      background: url('../images/domestic_transit_3x.png') no-repeat center center transparent;
      background-size: contain;
      width: 25px;
      height: 25px;
      left: -28px;
      top: 5px;
    }

    .order_transport_history_item_status_landing {
      background: url('../images/arrived_country_3x.png') no-repeat center center transparent;
      background-size: contain;
      width: 25px;
      height: 25px;
      left: -28px;
      top: 5px;
    }
    .order_transport_history_item_status_delivered,
    .order_transport_history_item_status_done {
      background: url('../images/delivered_parcel_active_3x.png') no-repeat center center transparent;
      background-size: contain;
      width: 25px;
      height: 25px;
      left: -28px;
      top: 0px;
    }
  }
}

//order_placed_3x.png

