/* start: Avatar */
.avatar-group {
  display: flex;
  align-items: center;
  justify-content: center;

  .avatar {
    position: relative;
    transition: 0.2s;
    text-align: center;
    cursor: pointer;
  }

  .avatar:not(:first-child) {
    margin-left: 0.5rem;
  }
  .avatar:hover {
    z-index: 1;
    transform: translateY(-0.5rem);
  }

  .has-more {
    background-color: #fff;
    display: inline;
    border-radius: 50%;
    border: 3px solid #fff;
    box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.1);
    vertical-align: middle;
    padding: 0.8rem;
  }
  .avatar-name {
    position: absolute;
    bottom: calc(100% + 0.5rem);
    left: 50%;
    transform: translateX(-50%);
    padding: 0.5rem 0.75rem;
    border-radius: 0.25rem;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    font-size: 0.875rem;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s;
  }
  .avatar-name::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border: 0.5rem solid;
    border-color: rgba(0, 0, 0, 0.7) transparent transparent;
  }
  .avatar:hover .avatar-name {
    opacity: 1;
    visibility: visible;
  }
  .avatar img {
    width: 3rem;
    height: 3rem;
    display: block;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid #fff;
    box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.1);
  }
  /* end: Avatar */
}

