
@mixin margin-auto {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
}


img.achievement_badge_inModal,
div.achievement_badge_inModal svg{
	width: 240px !important;
	height: auto !important
}



.user_achievement_element {
	cursor: pointer;
	text-decoration: none;
}


.user_achievement_element.badge {
	position: relative;
	margin: 1.5em 3em;
	width: 3em;
	height: 4.2em;
	border-radius: 10px;
	display: inline-block;
	top: 0;
	transition: all 0.2s ease;
	&:before,
	&:after {
		position: absolute;
		width: inherit;
		height: inherit;
		border-radius: inherit;
		background: inherit;
		content: "";
		@include margin-auto;
	}
	&:before {
		transform: rotate(60deg);
	}
	&:after {
		transform: rotate(-60deg);
	}
	&:hover {
		top: -4px;
	}
	.circle {
      width: 30px;
      height: 30px;
      position: absolute;
      /* background: #fff; */
      z-index: 10;
      border-radius: 50%;
      top: -12px;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
	}

  .ribbon {
    position: absolute;
    border-radius: 4px;
    padding: 2px;
    width: 100px;
    z-index: 11;
    color: #fff;
    bottom: 5px;
    left: 50%;
    margin-left: -50px;
    text-align: center;
    font-size: 10px;
    line-height: 1.4;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.27);
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    text-transform: uppercase;
    background: linear-gradient(to bottom right, #555 0%, #333 100%);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

}

.user_achievement_element {
		
	&.yellow {
		background: linear-gradient(to bottom right, #ffeb3b 0%, #fbc02d 100%);
		color: #ffb300;
	}

	&.orange {
		background: linear-gradient(to bottom right, #ffc107 0%, #f57c00 100%);
		color: #f68401;
	}

	&.pink {
		background: linear-gradient(to bottom right, #F48FB1 0%, #d81b60 100%);
		color: #dc306f;
	}

	&.red {
		background: linear-gradient(to bottom right, #f4511e 0%, #b71c1c 100%);
		color: #c62828;
	}

	&.purple {
		background: linear-gradient(to bottom right, #ab47bc 0%, #4527a0 100%);
		color: #7127a8;
	}

	&.teal {
			background: linear-gradient(to bottom right, #4DB6AC 0%, #00796B 100%);
		color: #34a297;
	}

	&.blue {
			background: linear-gradient(to bottom right, #4FC3F7 0%, #2196F3 100%);
		color: #259af3;
	}

	&.blue-dark {
			background: linear-gradient(to bottom right, #1976D2 0%, #283593 100%);
		color: #1c68c5;
	}

	&.green {
		background: linear-gradient(to bottom right, #cddc39 0%, #8bc34a 100%);
		color: #7cb342;
	}

	&.green-dark {
			background: linear-gradient(to bottom right, #4CAF50 0%, #1B5E20 100%);
		color: #00944a;
	}

	&.silver {
		background: linear-gradient(to bottom right, #E0E0E0 0%, #BDBDBD 100%);
		color: #9e9e9e;
	}

	&.gold {
		background: linear-gradient(to bottom right, #e6ce6a 0%, #b7892b 100%);
		color: #b7892b;
	}

}