
#sms_thread_wrap {

    .sms_inner {
        margin: 0;
        padding: 10px;
        list-style: none;
        position: relative;
    }

    .sms_inner li {
        border-bottom: 1px solid #ccc;
        padding: 10px 0;
    }

    .sms_inner li .wrap {
        display: flex;
        justify-content: space-between;
    }

    .sms_inner li .sms_short_detail{
        font-size: 14px;
        color: #068e08
    }

    .sms_inner li .sms_short_detail.unread{
        font-weight: bold;
    }

    .sms_inner li a,
    .sms_inner li button {
        text-decoration: none;
        color: unset;
        display: block;
        width: 100%;
    }

    /****
    * SMS detail
    */
    #sms_thread {
        position: relative;
        height: calc(100vh - 60px);

        .sms_header {
            padding: 10px;
        }

        .sms_body {
            height: calc(100vh - 150px);
            overflow: hidden;
            padding: 10px;
        }

        .sms_footer {
            position: static;
            background-color: #fff;
            padding: 10px;
            bottom: 0;
            left: 0
        }


        #sms_list {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        .direct-chat-text::after {
            border-width: 5px;
            margin-top: -5px;
        }

        .direct-chat-text::after, .direct-chat-text::before {
            border: solid transparent;
            border-right-color: #d2d6de;
            content: " ";
            height: 0;
            pointer-events: none;
            position: absolute;
            right: 100%;
            top: 15px;
            width: 0;
        }

        .direct-chat-text::before {
            border-width: 6px;
            margin-top: -6px;
        }

        .direct-chat-text {
            border-radius: .3rem;
            background-color: #d2d6de;
            border: 1px solid #d2d6de;
            color: #444;
            margin: 5px 0 0 10px;
            padding: 5px 10px;
            position: relative;
        }

        .direct-chat-msg {
            margin-bottom: 15px;
        }
        .direct-chat-timestamp {
            margin-left: 10px;
            font-size: 12px;
            color: #999;
        }

        .direct-chat-msg, .direct-chat-text {
            display: block;
        }

        .direct-chat-msg::after, .direct-chat-text {
            display: block;
            clear: both;
            content: "";
        }

        .direct-chat-messages {
            transition: -webkit-transform .5s ease-in-out;
            transition: transform .5s ease-in-out;
            transition: transform .5s ease-in-out,-webkit-transform .5s ease-in-out;
        }
        .direct-chat-messages {
            -webkit-transform: translate(0,0);
            transform: translate(0,0);
            height: 100%;
            overflow: auto;
            overflow-x: hidden;
            padding: 10px;
        }



        .direct-chat-msg.out > .direct-chat-text::after, .direct-chat-msg.out > .direct-chat-text::before {
            border-left-color: #007bff;
        }

        .out .direct-chat-text::after, .out .direct-chat-text::before {
            border-left-color: #d2d6de;
            border-right-color: transparent;
            left: 100%;
            right: auto;
        }

        .direct-chat-msg.out > .direct-chat-text {
            background-color: #007bff;
            border-color: #007bff;
            color: #fff;
            margin-right: 10px;
        }

        .direct-chat-msg.out > .direct-chat-timestamp {
            text-align: right;
        }


        .sms_empty {
            position:absolute; left:50%; top:50%; transform: translate(-50%, -50%);
            color: #999;
            text-align: center;
        }
    }


}


