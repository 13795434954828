#profile_header {

  .profile_background {
    background: url(../images/user_profile_background.jpg);
    height: 10rem; 
    width: 100%;
  }
  .profile_header {
    margin-top: -2rem;

    img {
      border-radius: 50%;
      width: 100px !important;
      height: 100px !important;
      border: 4px solid #ccc;
    }
  }


}


.user_referrer_placeholder {
  display: inline-block;
  width: 100px;
  height: 100px;
}