#group_income_hero_bar {
  background-color: #cbf2ff;
  padding: 20px;
  text-align: left;

  h1 {
    font-size: 24px;
    font-weight: 700;
    margin: 0;
  }
}

#group_income_sumarize {
  padding-bottom: 20px;
}

#group_income_page {
  .group_income_item {
    padding-bottom: 10px;
  }
}

