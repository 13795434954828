.order_status_transition {
  display: inline-grid;
  gap: 5px;
  .order_status_transition_wrap {
    overflow: auto;
    display: flex;
    align-items: start;

    .block > svg {
      overflow: auto;
      color: #fff;
      fill: #fff;
      height: 14px;
      width: 14px;
    }
    .block {
      background-color: rgb(48, 0, 37);
      color: #fff;
      padding: 5px 8px;
      display: inline-flex;
      text-decoration: none;
      font-size: 10px;
      transition: all 0.2s ease-in;
      line-height: 1.4;
      gap: 5px;
      align-items: center;
    }
    .block:hover {
      background-color: darkblue;
    }
    .block:nth-child(even) {
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
    }
    .block_1 {
      background-color: rgb(188, 0, 144);
    }
    .block_2 {
      background-color: rgb(139, 0, 106);
    }
    .block_3 {
      background-color: rgb(78, 0, 60);
    }
    .block_4 {
      background-color: rgb(57, 0, 44);
    }

    .block_5 {
      background-color: rgb(0, 119, 163);
    }

    .block_6 {
      background-color: rgb(0, 83, 114);
    }
    .block_7 {
      background-color: rgb(0, 156, 184);
    }
    .block_8 {
      background-color: rgb(0, 120, 141);
    }
    .block_9 {
      background-color: rgb(0, 92, 108);
    }

    .block_10 {
      background-color: rgb(215, 0, 0);
    }

    .block_11 {
      background-color: rgb(180, 2, 2);
    }

    .block_12 {
      background-color: rgb(110, 1, 1);
    }
  }
}

