.floating_banners_container {
  position: fixed;
  z-index: var(--p-z-index-3); /* Giữ nguyên thứ tự chồng */
  right: var(--p-space-400);
  bottom: var(--p-space-400);
  display: flex;
  flex-direction: column-reverse; /* Đảm bảo banner mới xuất hiện bên dưới */
  gap: var(--p-space-200); /* Khoảng cách giữa các banner */

  .floating_banner_content {
    margin-top: 5px;
    border-top: 1px solid #333333;
  }
}

.floating_banner {
  border-radius: var(--p-space-200);
  color: #fff;
  background-color: var(--p-color-bg-inverse);
  box-shadow:
    0 0 0.1875rem #0000001a,
    0 0.25rem 1.25rem #00000026;
  transform-origin: bottom right;
  padding: var(--p-space-150) var(--p-space-300);
  animation: fadeIn 0.3s ease-out forwards; /* Hiện lên với hiệu ứng */
}

.floating_banner.fadeOut {
  animation: fadeOut 0.3s ease-in forwards; /* Chỉ fadeOut khi thêm class fadeOut */
}

.floating_banner .floating_wrap {
  --spacing-x: var(--p-space-300);
  --spacing-y: var(--p-space-300);
  width: 18.75rem;
}

.floating_banner button,
.floating_banner button:hover,
.floating_banner a:hover,
.floating_banner a {
  color: #fff;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px) scale(0.95);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: translateY(20px) scale(0.95);
  }
}

